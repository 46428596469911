import React, { useState, useEffect, Suspense } from 'react';
import { Text, View, Pressable, Image, StyleSheet } from 'react-native';
import DrawerContent from './DrawerContent';
import { mapstrpublickey, ndk } from '../../api/constants.js';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { HomeScreenStyles } from '../../styles/HomeScreenStyles';
import CreateLocationForm from '../../screens/settings/CreateNewLocation/CreateLocationForm'
import { CommonStyles } from '../../styles/CommonStyles';
import menu from '../../assets/menu.svg';
import '../../styles/App.css';
import PropTypes from 'prop-types';

export default function HomeScreen({ route, navigation }) {

    const user = localStorage.getItem('user');
    const [UserState, setUserState] = React.useState(user);
    const nsec = localStorage.getItem('privado');
    const [NsecState, setNsecState] = React.useState(nsec);
    const ScheduledPostsLocalSt = localStorage.getItem('ScheduledPostsLocalSt');
    if(ScheduledPostsLocalSt == null){
        localStorage.setItem('ScheduledPostsLocalSt', JSON.stringify([]))
    }
    const [ScheduledPosts, setScheduledPosts] = React.useState(ScheduledPostsLocalSt);

    return (
        <View
            style={
                isMobile
                    ? [HomeScreenStyles.HomeWrapperMobile]
                    : [HomeScreenStyles.HomeWrapperDesktop]
            }
        >
            <View
                showsVerticalScrollIndicator={false}
                style={
                    isMobile ? [HomeScreenStyles.drawerMobile] : [HomeScreenStyles.drawerDesktop]
                }
            >
                <DrawerContent
                    navigation={navigation}
                    route={route}
                    ndk={ndk}
                    mapstrpublickey={mapstrpublickey}
                    UserState={UserState}
                    NsecState={NsecState}
                    setScheduledPosts={setScheduledPosts}
                    ScheduledPosts={ScheduledPosts}
                />
            </View>
            <View
                style={
                    isMobile
                        ? [HomeScreenStyles.mapOuterMobile]
                        : [HomeScreenStyles.mapOuterDesktop]
                }
            >
                <Suspense fallback={<Text>Loading...</Text>}>
                    
                    <CreateLocationForm 
                        name="SchedulePostForm"
                        ndk={ndk}
                        UserStateSettings={UserState}
                        mapstrpublickey={mapstrpublickey}
                        navigation={navigation}
                        nsec={NsecState}
                        setScheduledPosts={setScheduledPosts}
                        ScheduledPosts={ScheduledPosts}
                    />

                </Suspense>
            </View>
        </View>
    );
}

HomeScreen.propTypes = {
    route: PropTypes.object,
    navigation: PropTypes.object,
};
