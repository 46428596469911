const MapstrColors = {
    btc: '#FF9900',
    lightGrey: '#F4F4F5',
    primary: '#0d9488', //teal
    nostrPurple: '#8e30eb',
    darkGrey: '#39393D',
    mediumGrey: '#949191',
    error: '#EF4444',
};
export default MapstrColors;
