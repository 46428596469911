import React, { useEffect } from 'react';
import { View, Text, ScrollView, StyleSheet, Pressable, Image, FlatList } from 'react-native';
import MapstrListingCard from '../../screens/home/MapstrListingCard';
import { CommonStyles } from '../../styles/CommonStyles';
import { ListingsStyles } from './ListingsStyles';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import menu from '../../assets/menu.svg';
import menuDesktop from '../../assets/menuDesktop.svg';
import LoadingText from '../../screens/home/LoadingText';
import PropTypes from 'prop-types';

export default function Listings({
    navigation,
    HasNoListings,
    GlobalFeed,
    ListingsArray,
    ndk,
    currrentLat,
    currrentLng,
    map,
    setGlobalFeed,
    zoom,
    setLocations,
    radius,
    radiusOSM,
    setScheduledPosts,
    ScheduledPosts
}) {

    function PressedGlobalButton() {
        setGlobalFeed(true);
    }

    function PressedLocalButton() {
        setGlobalFeed(false);
    }

    return (
        <ScrollView
            showsVerticalScrollIndicator={false}
            contentContainerStyle={
                isMobile ? [CommonStyles.TabOuterMobile] : [CommonStyles.TabOuterDesktop]
            }
        >
            <View
                style={
                    isMobile ? [CommonStyles.TabWrapperMobile] : [CommonStyles.TabWrapperDesktop]
                }
            >
                <Pressable
                    onPress={() => {
                        navigation.navigate('Settings');
                    }}
                >
                    <Image source={isMobile ? menu : menuDesktop} style={[CommonStyles.Icon]} />
                </Pressable>
            </View>

            <FlatList
                style={[ListingsStyles.LocationList]}
                data={ListingsArray}
                renderItem={({ item, index }) => (
                    <MapstrListingCard
                        tags={item.tags}
                        index={index}
                        title={item.title}
                        content={item.content}
                        whenPublish={item.whenPublish}
                        lat={item.lat}
                        lng={item.lng}
                        id={item.id}
                        npub={item.npub}
                        dateCreated={item.dateCreated}
                        ndk={ndk}
                        ScrollId={item.locationUniqueIdentifier}
                        navigation={navigation}
                        showLocationScreenButton={true}
                        type={item.type}
                        currrentLat={currrentLat}
                        currrentLng={currrentLng}
                        map={map}
                        UserProfile={false}
                        zoom={zoom}
                        setLocations={setLocations}
                        setGlobalFeed={setGlobalFeed}
                        radius={radius}
                        radiusOSM={radiusOSM}
                        creator={item.creator}
                    />
                )}
                keyExtractor={(item) => Math.random()}
            />
        </ScrollView>
    );
}

Listings.propTypes = {
    navigation: PropTypes.object,
    HasNoListings: PropTypes.bool,
    GlobalFeed: PropTypes.bool,
    ListingsArray: PropTypes.array,
    ndk: PropTypes.object,
    currrentLat: PropTypes.number,
    currrentLng: PropTypes.number,
    map: PropTypes.object,
    setGlobalFeed: PropTypes.func,
    zoom: PropTypes.number,
    setLocations: PropTypes.func,
    radius: PropTypes.number,
    radiusOSM: PropTypes.number,
};
