import React, { useEffect } from 'react';
import { View, Text, ScrollView, StyleSheet, Pressable, Image, FlatList } from 'react-native';
import { GetEvents, publishStoredNotes } from '../../api/api.js';
import MapstrColors from '../../styles/MapstrColors';
import { CommonStyles } from '../../styles/CommonStyles';
import Listings from '../../ui/Listings/Listings';
import PropTypes from 'prop-types';

export default function DrawerContent({
    navigation,
    ndk,
    currrentLat,
    currrentLng,
    map,
    HasNoListings,
    setHasNoListings,
    GlobalFeed,
    setGlobalFeed,
    route,
    zoom,
    setLocations,
    radius,
    radiusOSM,
    UserState,
    NsecState
}) {
    const ListingsArray = [];
    let LocalHasNoListings = false;
    const scheduledPosts = JSON.parse(localStorage.getItem("ScheduledPostsLocalSt"))
    
    // display posts
    scheduledPosts.forEach((event, index) => {
        ListingsArray.unshift({
            type: 'node',
            content: event.content,
            id: event.id,
            npub: null,
            whenPublish: event.whenPublish,
        });
    });

    useEffect(() => {
        const TENMINUTE_MS = 600000;
        const interval = setInterval(() => {
            publishStoredNotes( ndk, UserState, NsecState )
        }, TENMINUTE_MS);
        return () => clearInterval(interval);
    }, []);

    return (
        <Listings
            navigation={navigation}
            HasNoListings={HasNoListings}
            GlobalFeed={GlobalFeed}
            ListingsArray={ListingsArray}
            ndk={ndk}
            currrentLat={currrentLat}
            currrentLng={currrentLng}
            map={map}
            setGlobalFeed={setGlobalFeed}
            zoom={zoom}
            setLocations={setLocations}
            radius={radius}
            radiusOSM={radiusOSM}
        />
    );
}

DrawerContent.propTypes = {
    navigation: PropTypes.object,
    ndk: PropTypes.object,
    locations: PropTypes.array,
    currrentLat: PropTypes.number,
    currrentLng: PropTypes.number,
    map: PropTypes.object,
    HasNoListings: PropTypes.bool,
    setHasNoListings: PropTypes.func,
    GlobalFeed: PropTypes.bool,
    setGlobalFeed: PropTypes.func,
    route: PropTypes.object,
    zoom: PropTypes.number,
    setLocations: PropTypes.func,
    radius: PropTypes.number,
    radiusOSM: PropTypes.number,
};
