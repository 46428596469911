import React, { useState, useEffect } from 'react';
import {
    Text,
    View,
    ScrollView,
    TextInput,
    Pressable,
    StyleSheet,
    Image,
    ActivityIndicator,
} from 'react-native';
import { Formik, Field, Form } from 'formik';
import { saveEventToStorage } from '../../../api/api.js';
import NDK, { NDKPrivateKeySigner, NDKNip07Signer } from '@nostr-dev-kit/ndk';
import * as yup from 'yup';
import { CommonStyles } from '../../../styles/CommonStyles';
import MapstrColors from '../../../styles/MapstrColors';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import PropTypes from 'prop-types';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function CreateLocationForm({
    name,
    ndk,
    UserStateSettings,
    mapstrpublickey,
    setMarkersProp,
    setMapstrListingsProp,
    navigation,
    nsec,
    setScheduledPosts,
    ScheduledPosts
}) {

    const [InitialDateTime, setInitialDateTime] = React.useState(new Date());
    const [userMessage, setUserMessage] = React.useState(null);
    const [content, setContent] = React.useState('');
    const createValidationSchema = yup.object().shape({
        datetime: yup.string().min(1, 'Enter a date and time for this post to publish').required('true'),
        content: yup.string().min(1, 'Enter a Description for this location').required('true'),
    });
    let handleColor = (time) => {
    return time.getHours() > 12 ? "text-success" : "text-error";
    };

    return (
        <Formik
            enableReinitialize={true}
            validationSchema={createValidationSchema}
            initialValues={{
                datetime: InitialDateTime,
                // timezone: timezone,
                content: content,
            }}
            onSubmit={(values, { resetForm }) => {

                console.log(values)
                setUserMessage(<ActivityIndicator size="small" color={MapstrColors['primary']} />);
                let signer = null;
                if (nsec == "undefined") {
                    signer = new NDKNip07Signer();
                } else {
                    signer = new NDKPrivateKeySigner(nsec);
                }
                saveEventToStorage(
                    ndk,
                    UserStateSettings,
                    values,
                    signer,
                    mapstrpublickey,
                    setUserMessage,
                    setMarkersProp,
                    setMapstrListingsProp,
                    setScheduledPosts,
                    ScheduledPosts
                );
                resetForm();
                window.location.reload();
            }}
        >
            {({ handleChange, handleBlur, handleSubmit, values, errors, isValid, field }) => (
                <>
                    <View style={[CommonStyles.wrapper]} >
                        <TextInput
                            style={
                                errors.content === 'true'
                                    ? CommonStyles.inputFieldError
                                    : CommonStyles.inputField
                            }
                            multiline="true"
                            rows={5}
                            id="content"
                            name="content"
                            placeholder="write here..."
                            value={values.content}
                            onChange={handleChange('content')}
                            onBlur={(e) => {
                                setContent(e.nativeEvent.text);
                                handleBlur('content');
                            }}
                        />

                        <DatePicker
                            name="datetime"
                            showTimeSelect
                            selected={InitialDateTime}
                            onChange={(date) => setInitialDateTime(date)}
                            timeClassName={handleColor}
                            dateFormat="MMMM d, yyyy h:mm aa"
                        />

                        <Pressable
                            onPress={handleSubmit}
                            disabled={!isValid}
                            style={[CommonStyles.submit]}
                        >
                            <Text style={[CommonStyles.submitInner]}>Schedule post</Text>
                        </Pressable>

                        <Text style={[CommonStyles.UserMessage]}>{userMessage}</Text>
                    </View>
                </>
            )}
        </Formik>
    );
}

CreateLocationForm.propTypes = {
    name: PropTypes.string,
    ndk: PropTypes.object,
    UserStateSettings: PropTypes.string,
    mapstrpublickey: PropTypes.string,
    setMarkersProp: PropTypes.func,
    setMapstrListingsProp: PropTypes.func,
    navigation: PropTypes.object,
    nsec: PropTypes.string,
};

export default CreateLocationForm;

// <select
//                             style={
//                                 errors.timezone === 'true'
//                                     ? CommonStyles.inputFieldError
//                                     : CommonStyles.inputField
//                             }
//                             name="timezone"
//                             placeholder="timezone to publish in"
//                             value={values.timezone}
//                             onChange={handleChange('timezone')}
//                             onBlur={(e) => {
//                                 setTimezone(e.target.value);
//                                 handleBlur('timezone');
//                             }}
//                         >
//                             <option value={null} label="Select time zone">
//                                 Select time zone
//                             </option>

//                             <option value="Asia/Bangkok" label="Asia/Bangkok">
//                                 Asia/Bangkok
//                             </option>

//                             <option value="America/Los_Angeles" label="America/Los_Angeles">
//                                 America/Los_Angeles
//                             </option>

//                             <option value="Europe/Berlin" label="Europe/Berlin">
//                                 Europe/Berlin
//                             </option>
//                             <option value="America/New_York" label="America/New_York">
//                                 America/New_York
//                             </option>
//                             <option value="Australia/Perth" label="Australia/Perth">
//                                 Australia/Perth
//                             </option>
//                             <option value="Pacific/Tongatapu" label="Pacific/Tongatapu">
//                                 Pacific/Tongatapu
//                             </option>
//                             <option value="Africa/Djibouti" label="Africa/Djibouti">
//                                 Africa/Djibouti
//                             </option>
//                             <option value="Asia/Kamchatka" label="Asia/Kamchatka">
//                                 Asia/Kamchatka
//                             </option>

//                         </select>
