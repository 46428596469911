import React, { useState } from 'react';
import { Pressable, View, Text, Image, StyleSheet } from 'react-native';
import MapstrColors from '../../styles/MapstrColors';
import { CommonStyles } from '../../styles/CommonStyles';
import PropTypes from 'prop-types';
import { removeStoredPost } from '../../api/api'

export default function MapstrListingCard({
    title,
    tags,
    content,
    whenPublish,
    lat,
    lng,
    id,
    npub,
    dateCreated,
    ndk,
    ScrollId, // Todo: I dont think you really need this anymore. Can take it out at some point. No scrolling funcitonality anymore.
    navigation,
    showLocationScreenButton,
    type,
    map,
    currrentLat,
    currrentLng,
    UserProfile,
    zoom,
    setLocations,
    setGlobalFeed,
    radius,
    radiusOSM,
    creator,
    index
}) {

    // Relates to the modal
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    // CSS related
    const [mediaQueryWidth, setMediaQueryWidth] = React.useState('100%');
    const [mediaQueryMarginCard, setMediaQueryMarginCard] = React.useState('1em');
    const [mediaQueryMarginModal, setMediaQueryMarginModal] = React.useState('1em');
    const [mediaQueryTransform, setMediaQueryTransform] = React.useState('translate(-3%, -4%)');
    const [mediaQueryMaxWidth, setMediaQueryMaxWidth] = React.useState('85%');
    
    const [NiceDate, setNiceDate] = React.useState(new Date(whenPublish))
    return (
        <View
            id={id}
            className={ScrollId}
            style={
                showLocationScreenButton
                    ? [CardStyles.cardOuter]
                    : [CardStyles.cardOutercardOuterReviewListings]
            }
        >   
            <Text style={CommonStyles.paragraph}>{content}</Text>

            <Pressable
                style={CardStyles.ctaButtonWide}
                onPress={() => {
                        var postID = content.substring(0,4);
                        removeStoredPost(postID)
                    }
                }
            >
                <Text style={CardStyles.ctaButtonInner}>Delete</Text>
            </Pressable>

            <Text style={CommonStyles.paragraph}>
                <Text style={CommonStyles.bolded600Text}>Scheduled for:</Text>
                {NiceDate.toTimeString()}
            </Text>
        </View>
    );
}

const CardStyles = StyleSheet.create({
    cardOuter: {
        padding: '1em',
        maxWidth: '100%',
        borderBottomWidth: '1px',
        borderBottomColor: MapstrColors['lightGrey'],
    },
    cardOuterReviewListings: {
        padding: '1em',
        maxWidth: '100%',
    },
    cardUserMetaWrapper: {
        flexDirection: 'row',
    },
    profileImage: {
        height: '3em',
        width: '3em',
        borderRadius: '50%',
        marginRight: '1em',
        marginBottom: '1em',
    },
    cardUserMeta: {
        width: 'fit-content',
    },
    date: {
        fontSize: '0.618em',
    },
    ctaButtonWide: {
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: '0.5em',
        marginBottom: '0.5em',
        padding: '0.5em',
        borderRadius: '10px',
        backgroundColor: MapstrColors['lightGrey'],
        width: '100%',
    },
    ctaButton: {
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: '0.5em',
        marginBottom: '0.5em',
        padding: '0.5em',
        borderRadius: '10px',
        backgroundColor: MapstrColors['lightGrey'],
        width: '49%',
    },
    ctaButtonInner: {
        textAlign: 'center',
        fontSize: '1em',
    },
    acceptBTC: {
        fontWeight: 600,
        fontSize: '1em',
        padding: '0.168em',
        color: MapstrColors['btc'],
        wordBreak: 'anywhere',
    },
    ctaIcon: {
        height: '1em',
        width: '1em',
        marginLeft: '1em',
        fontSize: '1.2em',
    },
    CTAWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
    },
});

MapstrListingCard.propTypes = {
    title: PropTypes.string,
    tags: PropTypes.array,
    content: PropTypes.string,
    lat: PropTypes.number,
    lng: PropTypes.number,
    id: PropTypes.string,
    npub: PropTypes.string,
    dateCreated: PropTypes.number,
    ndk: PropTypes.object,
    ScrollId: PropTypes.number,
    navigation: PropTypes.object,
    showLocationScreenButton: PropTypes.bool,
    type: PropTypes.string,
    map: PropTypes.object,
    currrentLat: PropTypes.number,
    currrentLng: PropTypes.number,
    UserProfile: PropTypes.bool,
    zoom: PropTypes.number,
    setLocations: PropTypes.func,
    setGlobalFeed: PropTypes.func,
    radius: PropTypes.number,
    radiusOSM: PropTypes.number,
    creator: PropTypes.string,
};
