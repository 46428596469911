import React, { useState, Suspense } from 'react';
import { View, Text } from 'react-native';
import { CommonStyles } from '../../styles/CommonStyles';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import PropTypes from 'prop-types';

export default function SettingsTabBar({ navigation }) {
    return (
        <View
            style={
                isMobile
                    ? [CommonStyles.SettingsTabBarMobile]
                    : [CommonStyles.SettingsTabBarDesktop]
            }
        ></View>
    );
}

SettingsTabBar.propTypes = {
    navigation: PropTypes.object,
};
