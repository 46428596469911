import React, { useState, useEffect } from 'react';
import { Pressable, StyleSheet, View } from 'react-native';
import NDK, { NDKEvent, NDKPrivateKeySigner, NDKNip07Signer } from '@nostr-dev-kit/ndk';

export function setRelayListArray() {
    let AlteredRelayListLocal = localStorage.getItem('AlteredRelayListLocal');
    let RelayList = [
        'wss://nostr.wine',
        'wss://nostr.mom',
        'wss://nos.lol',
        'wss://relay.nostr.band',
        'wss://relayable.org',
        'wss://lightningrelay.com',
        'wss://nostr.bongbong.com',
    ];
    if (AlteredRelayListLocal) {
        let AlteredRelayListParseLocal = JSON.parse(AlteredRelayListLocal);
        RelayList = AlteredRelayListParseLocal;
    }
    return RelayList;
}

export function removeRelay(title, RelayList, setRelayList) {
    const AlteredRelayList = [];
    RelayList.map((relay, index) => {
        if (relay !== title) {
            AlteredRelayList.push(relay);
        }
    });

    setRelayList(AlteredRelayList);
    let AlteredRelayListLocal = JSON.stringify(AlteredRelayList);
    localStorage.setItem('AlteredRelayListLocal', AlteredRelayListLocal);
}

export function addRelay(title, RelayList, setRelayList) {
    const AlteredRelayList = [];
    RelayList.map((relay, index) => {
        AlteredRelayList.push(relay);
    });
    AlteredRelayList.push(title);
    setRelayList(AlteredRelayList);
    let AlteredRelayListLocal = JSON.stringify(AlteredRelayList);
    localStorage.setItem('AlteredRelayListLocal', AlteredRelayListLocal);
}

export async function connectNDK(ndk) {
    return await ndk.connect();
}

export async function saveEventToStorage(
    ndk,
    npub,
    formValues,
    signer,
    mapstrpublickey,
    setUserMessage,
    setMarkersProp,
    setMapstrListingsProp,
    setScheduledPosts
) {
    const ndkEvent = new NDKEvent(ndk);
    ndkEvent.kind = 1;
    ndkEvent.content = formValues.content;
    ndkEvent.pubkey = npub;
    ndkEvent.whenPublish = formValues.datetime;
    ndkEvent.id = formValues.content.substring(0,4);
    var storedPosts = JSON.parse(localStorage.getItem("ScheduledPostsLocalSt"));
    storedPosts.unshift(ndkEvent);
    localStorage.setItem("ScheduledPostsLocalSt", JSON.stringify(storedPosts));
    setUserMessage("Added this post to the queue");
}

export async function removeStoredPost(postID){

    var newPostsArray = []
    var storedPosts = JSON.parse(localStorage.getItem("ScheduledPostsLocalSt"));
    storedPosts.forEach((post, index) => {
        if(postID != post.id){
            newPostsArray.push(post)
        }
    })
    localStorage.setItem("ScheduledPostsLocalSt", JSON.stringify(newPostsArray));
    window.location.reload();
}

export async function publishStoredNotes( ndk, UserState, NsecState ){
    
    var storedPosts = JSON.parse(localStorage.getItem("ScheduledPostsLocalSt"));
    let signer = null
    if ( NsecState == 'undefined' || UserState == 'undefined' ) {
        signer = new NDKNip07Signer();
    } else {
        signer = new NDKPrivateKeySigner(NsecState);
    }
    
    storedPosts.forEach((event, index) => {

        const whenPublish = new Date(event.whenPublish);
        const current = new Date();

        if (whenPublish.getTime() < current.getTime()) {
            createEventMarker(
                ndk,
                UserState,
                event.content,
                event.whenPublish,
                signer,
                // mapstrpublickey
            )
        } else if (whenPublish.getTime() > current.getTime()) {
            // publishing time is yet to occur so wait - no publishing
        }    
    })
}

export async function createEventMarker(
    ndk,
    npub,
    content,
    whenPublish,
    signer
) {
    const ndkEvent = new NDKEvent(ndk);
    ndkEvent.kind = 1;
    ndkEvent.content = content
    ndkEvent.pubkey = npub;

    ndkEvent.sign(signer).then((signingResult) => {
        if (window.live) {
            ndkEvent
                .publish()
                .then((publishingResult) => {
                    removeStoredPost(ndkEvent.content.substring(0,4))
                    console.log(publishingResult)
                })
                .catch((error) => {
                    console.log(error);
                    return false;
                });
        } else {
            console.log('Dev Mode');
            console.log(ndkEvent);
        }
    });
}
